import React, { useState, useRef, useEffect, useCallback } from 'react';
import './ImgToPixelArt.css';

function ImgToPixelArt() {
  const [imageSrc, setImageSrc] = useState(null);
  const [pixelSize, setPixelSize] = useState(10);
  const canvasRef = useRef(null);

  useEffect(() => {
    document.title = "Convert Image to Pixel Art"; 

    // 动态插入 Google Analytics 跟踪代码
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-8V2GPV2KLV`;
    script.async = true;
    document.head.appendChild(script);

    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-8V2GPV2KLV');
    `;
    document.head.appendChild(inlineScript);

    return () => {
      // 如果组件卸载，移除插入的脚本
      document.head.removeChild(script);
      document.head.removeChild(inlineScript);
    };
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setImageSrc(e.target.result);
      reader.readAsDataURL(file);
    }
  };
  

  const convertToPixelArt = useCallback(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
      // Set canvas size to match the image
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the original image
      ctx.drawImage(img, 0, 0);

      // Get image data
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      // Pixelate the image
      for (let y = 0; y < canvas.height; y += pixelSize) {
        for (let x = 0; x < canvas.width; x += pixelSize) {
          const red = data[((y * canvas.width + x) * 4)];
          const green = data[((y * canvas.width + x) * 4) + 1];
          const blue = data[((y * canvas.width + x) * 4) + 2];

          ctx.fillStyle = `rgb(${red},${green},${blue})`;
          ctx.fillRect(x, y, pixelSize, pixelSize);
        }
      }
    };

    img.src = imageSrc;
  }, [imageSrc, pixelSize]);

  const handleDownload = useCallback(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
      const link = document.createElement('a');
      link.download = 'pixel-art.png';
      link.href = image;
      link.click();
    }
  }, []);

  useEffect(() => {
    if (imageSrc) {
      convertToPixelArt();
    }
  }, [imageSrc, pixelSize, convertToPixelArt]);

  return (
    <div className="img-to-pixel-art-wrapper">
      <div className="img-to-pixel-art">
        <h1>Image to Pixel Art Converter</h1>
        
        <div className="introduction">
          <p>
            Welcome to the Image to Pixel Art Converter! This tool allows you to transform your photos and images into charming pixel art creations. 
            It's perfect for creating retro-style graphics, game assets, or giving your photos a unique, nostalgic look.
          </p>
          <div className="instructions">
            <h2>How to use:</h2>
            <ol>
              <li>Click the "Choose File" button to upload a photo or image from your device.</li>
              <li>Use the "Pixel Size" slider to adjust the level of pixelation:
                <ul>
                  <li>Smaller values create more detailed pixel art.</li>
                  <li>Larger values create more abstract, blocky pixel art.</li>
                </ul>
              </li>
              <li>The pixel art will generate automatically as you make adjustments.</li>
              <li>Click the "Download Pixel Art" button to save your creation.</li>
            </ol>
          </div>
          <p>
            Experiment with different pixel sizes to achieve the desired effect. Each image will look unique at different pixelation levels, 
            so don't hesitate to try various settings!
          </p>
        </div>

        <div className="converter">
          <div className="controls">
            <div className="file-controls">
              <input type="file" accept="image/*" onChange={handleFileChange} id="file-input" className="file-input" />
              <label htmlFor="file-input" className="button file-input-label">Choose File</label>
              {imageSrc && (
                <button onClick={handleDownload} className="button download-button">Download Pixel Art</button>
              )}
            </div>
            
            <div className="pixel-size-control">
              <label htmlFor="pixel-size">Pixel Size: {pixelSize}px</label>
              <input
                id="pixel-size"
                type="range"
                min="1"
                max="50"
                value={pixelSize}
                onChange={(e) => setPixelSize(parseInt(e.target.value))}
                className="pixel-size-slider"
              />
            </div>
          </div>

          <div className="canvas-container">
            {imageSrc ? (
              <canvas ref={canvasRef} className="pixel-art-canvas"></canvas>
            ) : (
              <div className="placeholder">
                Upload an image to see the pixel art here
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImgToPixelArt;
