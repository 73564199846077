import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ImgToSrc from './components/ImgToSrc/ImgToSrc';   
import ImgToPixelArt from './ImgToPixelArt/ImgToPixelArt';
// import ABTestCalculator from './components/AbTest/ABTestCalculator';
import Home from './components/Home';   
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <nav className="navbar">
          <ul className="navbar-menu">
           
          </ul>
        </nav>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/img-to-src" element={<ImgToSrc />} />
          <Route path="/img-to-pixel-art" element={<ImgToPixelArt />} />
          {/* <Route path="/ab-test" element={<ABTestCalculator />} /> */}
        </Routes>

        <footer className="footer">
          <p>All resources on this site are free to use and share, helping you create without limits.</p>
          
    </footer>
      </div>
    </Router>
    
  );
}

export default App;
