import React, { useState, useEffect, useCallback } from 'react';
import './ImgToSrc.css';

function ImgToSrc() {
  const [imageSrc, setImageSrc] = useState('');
  const [asciiArt, setAsciiArt] = useState('');
  const [width, setWidth] = useState(100);
  const [height, setHeight] = useState(50);
  const [originalWidth, setOriginalWidth] = useState(100);
  const [originalHeight, setOriginalHeight] = useState(50);
  const [copySuccess, setCopySuccess] = useState('');
  const [brightness, setBrightness] = useState(50);
  const [chars, setChars] = useState(' .,:;i1tfLCG08@');

  useEffect(() => {
    document.title = "Convert Image to ASCII Art"; 
    document.querySelector('meta[name="description"]')?.setAttribute("content", "Convert your images into ASCII art with ImgToSrc. Upload an image, set dimensions, and generate unique ASCII art.");

    // 动态插入 Google Analytics 跟踪代码
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-8V2GPV2KLV`;
    script.async = true;
    document.head.appendChild(script);

    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-8V2GPV2KLV');
    `;
    document.head.appendChild(inlineScript);

    return () => {
      // 如果组件卸载，移除插入的脚本
      document.head.removeChild(script);
      document.head.removeChild(inlineScript);
    };
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          setImageSrc(img.src);
          console.log(img.width, img.height)
          const recommendedWidth = Math.min(img.width, 100); 
          const recommendedHeight = Math.round(img.height * (recommendedWidth / img.width) / 2);
          setOriginalWidth(recommendedWidth);
          setOriginalHeight(recommendedHeight);
          setWidth(recommendedWidth);
          setHeight(recommendedHeight);
          const ascii = convertToAscii(img, recommendedWidth, recommendedHeight, chars);
          setAsciiArt(ascii);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const handleGenerate = useCallback(() => {
    if (imageSrc) {
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        const ascii = convertToAscii(img, width, height, chars);
        setAsciiArt(ascii);
      };
    }
  }, [imageSrc, width, height, chars]);  // 确保只在依赖项变化时重新生成 handleGenerate

  const handleBrightnessChange = (e) => {
    const newBrightness = parseInt(e.target.value, 10);
    setBrightness(newBrightness);
    const newChars = adjustChars(newBrightness);
    setChars(newChars);
    handleGenerate();  // Regenerate ASCII art when brightness changes
  };

  const handleWidthChange = (e) => {
    const newWidth = Math.min(800, Math.max(10, parseInt(e.target.value, 10) || 10));
    setWidth(newWidth);
  };

  const handleHeightChange = (e) => {
    const newHeight = Math.min(800, Math.max(10, parseInt(e.target.value, 10) || 10));
    setHeight(newHeight);
  };

  const handleBrightnessInputChange = (e) => {
    const newBrightness = Math.min(100, Math.max(0, parseInt(e.target.value, 10) || 0));
    setBrightness(newBrightness);
    const newChars = adjustChars(newBrightness);
    setChars(newChars);
    handleGenerate();
  };

  const adjustChars = (brightness) => {
    const baseChars = ' .,:;i1tfLCG08@';
    const newBrightness = 100 - brightness;
    if (newBrightness === 0) {
      return ' ';  // Return a single space character for 0 brightness
    } else if (newBrightness < 50) {
      return baseChars.slice(0, Math.max(1, Math.ceil(baseChars.length * (newBrightness / 50))));
    } else if (newBrightness > 50) {
      return baseChars.padStart(Math.ceil(baseChars.length * (newBrightness / 50)), '@');
    }
    return baseChars;
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(asciiArt).then(() => {
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000);
    }, (err) => {
      console.error('Failed to copy: ', err);
      setCopySuccess('Failed to copy');
    });
  };

  const handleReset = () => {
    setWidth(originalWidth);
    setHeight(originalHeight);
    handleGenerate();
  };

  const convertToAscii = (img, width, height, chars) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(img, 0, 0, width, height);
    
    const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const ascii = [];
    const charsArray = chars.split('');

    for (let y = 0; y < imgData.height; y++) {
      let row = '';
      for (let x = 0; x < imgData.width; x++) {
        const offset = (y * imgData.width + x) * 4;
        const red = imgData.data[offset];
        const green = imgData.data[offset + 1];
        const blue = imgData.data[offset + 2];
        const brightness = (red + green + blue) / 3;
        const charIdx = Math.min(charsArray.length - 1, Math.floor((brightness / 255) * charsArray.length));
        row += charsArray[charIdx];
      }
      ascii.push(row);
    }
    return ascii.join('\n');
  };

  useEffect(() => {
    handleGenerate()
  }, [handleGenerate]);

  return (
    <div className="img-to-src-wrapper">
      <div className="img-to-src">
        <h1>Image to ASCII Art Converter</h1>
        
        <div className="introduction">
          <p>
            Welcome to the Image to ASCII Art Converter! This tool allows you to transform your images or photos into unique ASCII art representations. 
            It's perfect for adding a retro touch to your digital content or creating text-based versions of your favorite images.
          </p>
          <div className="getting-started">
            <h2>To get started:</h2>
            <ol>
              <li>Click the "Choose File" button to upload an image from your device.</li>
              <li>
                Use the sliders or input boxes to adjust the width, height, and brightness of your ASCII art:
                <ul>
                  <li><strong>Width and Height:</strong> Control the size of your ASCII art (10-800 pixels).</li>
                  <li><strong>Brightness:</strong> Adjust the contrast of your ASCII art (0-100%).</li>
                </ul>
              </li>
              <li>The ASCII art will generate automatically as you make adjustments.</li>
              <li>Click the "Copy ASCII Art" button to copy your creation to the clipboard.</li>
            </ol>
          </div>
          <p>
            Experiment with different settings to achieve the best result for your image. Lower dimensions often work well for more abstract representations, 
            while higher dimensions can capture more detail. Adjusting the brightness can help bring out features in darker or lighter images.
          </p>
          <p>
            The "Reset" button will restore the recommended dimensions based on your uploaded image, helping you find a good starting point.
          </p>
        </div>
      
        <div className="controls">
          <div className="control-group">
            <label htmlFor="width-slider">Width:</label>
            <div className="slider-container">
              <input
                id="width-slider"
                type="range"
                min="10"
                max="800"
                value={width}
                onChange={handleWidthChange}
                className="dimension-slider"
              />
              <input
                type="number"
                min="10"
                max="800"
                value={width}
                onChange={handleWidthChange}
                className="dimension-input"
              />
              <span className="dimension-unit">px</span>
            </div>
          </div>
          <div className="control-group">
            <label htmlFor="height-slider">Height:</label>
            <div className="slider-container">
              <input
                id="height-slider"
                type="range"
                min="10"
                max="800"
                value={height}
                onChange={handleHeightChange}
                className="dimension-slider"
              />
              <input
                type="number"
                min="10"
                max="800"
                value={height}
                onChange={handleHeightChange}
                className="dimension-input"
              />
              <span className="dimension-unit">px</span>
            </div>
          </div>
          <div className="control-group">
            <label htmlFor="brightness-slider">Brightness:</label>
            <div className="slider-container">
              <input
                id="brightness-slider"
                type="range"
                min="0"
                max="100"
                value={brightness}
                onChange={handleBrightnessChange}
                className="dimension-slider"
              />
              <input
                type="number"
                min="0"
                max="100"
                value={brightness}
                onChange={handleBrightnessInputChange}
                className="dimension-input"
              />
              <span className="dimension-unit">%</span>
            </div>
          </div>
        </div>
        <div className="button-container">
          <input type="file" accept="image/*" onChange={handleFileChange} className="file-input" />
          {asciiArt && (
            <>
              <button onClick={handleReset} className="reset-button">Reset</button>
              <button onClick={copyToClipboard} className="copy-button">
                Copy ASCII Art
              </button>
              {copySuccess && <span className="copy-success">{copySuccess}</span>}
            </>
          )}
        </div>
        <div className="ascii-art-container">
          <pre className="ascii-art">{asciiArt}</pre>
        </div>
      </div>
    </div>
  );
}

export default ImgToSrc;
