import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


import './Home.css';  

function Home() {

  const location = useLocation();
  useEffect(() => {
    document.title = 'Home - Tool Aggregation Platform';
  }, [location]);

  return (
    <div className="home-container">
      <h1 className="home-title">Home - Tool Aggregation Platform</h1>
      <div className="home-links">
        <a href="/img-to-src" className="home-link">Go to Image URL to Image</a>
      </div>
    </div>
  );
}

export default Home;
